import React, { ReactElement } from 'react';
import Auth from '../components/layouts/Auth';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { globalTranslationFiles } from '../lib/trasnlation';
import { DEFAULT_LANGUAGE } from '../lib/constants';
import { default as LoginPage } from '../components/pages-content/login/Login';
import { login } from '../lib/auth';

const Login = () => <LoginPage login={login} />;

Login.getLayout = function getLayout(page: ReactElement) {
  return <Auth>{page}</Auth>;
};

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale || DEFAULT_LANGUAGE, [
        ...globalTranslationFiles,
        'auth',
      ])),
    },
  };
}

export default Login;
