import CardAuth from '../../elements/CardAuth';
import Form from '../../elements/Form';
import { TLoginForm, TLoginProps } from '../../../types/app';
import { loginSchema } from '../../../lib/yup';
import Input from '../../elements/Input';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import Button from '../../elements/Button';
import { UseFormReturn } from 'react-hook-form';
import useToast from '../../../hooks/useToast';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRouter } from 'next/router';

const appTestToken = process.env.NEXT_PUBLIC_TEST_TOKEN || '';

const Login = (props: TLoginProps) => {
  const router = useRouter();
  const { t } = useTranslation('validation');
  const { t: common } = useTranslation('common');
  const { t: auth } = useTranslation('auth');
  const [loading, setLoading] = useState(false);
  const { notifyError } = useToast();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { testToken } = router.query;
  const [canLogin, setCanLogin] = useState(appTestToken === testToken);

  const onSubmit = (
    data: TLoginForm,
    { setError, setFocus }: UseFormReturn<TLoginForm>
  ) => {
    const recaptchaValue = recaptchaRef?.current?.getValue();
    const canContinue = !!recaptchaValue || appTestToken === testToken;
    if (!canContinue) return;

    setLoading(true);

    props
      .login({
        ...data,
        testToken: testToken as string,
        language: router.locale || 'en',
        recaptchaToken: recaptchaValue || '',
      })
      .then((data) => {})
      .catch((error) => {
        setFocus('email');
        setError('email', {
          type: 'manual',
          message: ' ',
        });

        setError('password', {
          type: 'manual',
          message: ' ',
        });
        notifyError(auth('Invalid credentials'));
        setLoading(false);
      });
  };

  return (
    <div>
      <CardAuth>
        <div className="md:py-[82px] py-0 lg:px-[240px] px-6">
          <Form<TLoginForm>
            className="space-y-[30px]"
            schema={loginSchema(t)}
            onSubmit={onSubmit}
          >
            {([
              {
                register,
                control,
                formState: { errors },
              },
            ]) => (
              <>
                <Input
                  label={common('Email')}
                  placeholder={common('Enter your email address')}
                  error={errors.email?.message}
                  register={register('email')}
                  className="max-w-[420px] mx-auto"
                  autocomplete="email"
                  labelHasStar={false}
                />

                <Input
                  label={common('Password')}
                  placeholder={common('Enter your password')}
                  type="password"
                  autocomplete="current-password"
                  error={errors.password?.message}
                  register={register('password')}
                  className="max-w-[420px] mx-auto"
                  labelHasStar={false}
                />

                <div className="flex-center">
                  <Link href="/reset-password">
                    <a className="text-primary text-body-3 font-normal underline">
                      {common('Forgot password')}
                    </a>
                  </Link>
                </div>

                {appTestToken !== testToken && (
                  <div className="relative flex items-center justify-center col-span-full">
                    <ReCAPTCHA
                      sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
                      ref={recaptchaRef}
                      onExpired={() => setCanLogin(false)}
                      onChange={() => setCanLogin(true)}
                    />
                  </div>
                )}

                <div className="flex-center pt-[12px]">
                  <Button
                    disabled={loading || !canLogin}
                    loading={loading}
                    type="submit"
                    label={common('login')}
                  />
                </div>
              </>
            )}
          </Form>
        </div>
      </CardAuth>
    </div>
  );
};

export default Login;
